.results {
  width: 1100px;
  padding: 20px;
  background-color: #fff;
  position: relative;
  // height: fit-content;
  min-height: 250px;

  .no_result {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    span {
      font-size: 14px;
      font-weight: 600;
    }
  }

  .header {
    font-size: 13px;
    color: #2d2d2d;
  }

  .result {
    margin-top: 15px;
    border-bottom: 1px solid var(--primary-grey);
    padding-bottom: 10px;

    .result_header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h4 {
        font: 14px;
        text-decoration: underline;
      }

      span {
        font-size: 13px;
        font-weight: 800;
      }
    }

    @media screen and (max-width: 600px) {
      .result_header {
        flex-direction: column;
        align-items: flex-start;

        h4 {
          margin-bottom: 10px;
        }
      }
    }

    p {
      margin-top: 10px;
      text-align: justify;
      font-size: 12px;
      display: -webkit-box !important;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 10px;

      span {
        text-transform: capitalize;
      }
    }

    @media screen and (max-width: 500px) {
      .result_header {
        h4 {
          font: 12px;
          margin-right: 10px;
        }

        span {
          font-size: 12px;
        }
      }
      p {
        font-size: 10px;
      }
    }

    .result_category {
      display: block;
      margin-top: 8px;
      color: #979797;
      font-size: 10px;
    }

    .action_buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 15px;

      button {
        padding: 5px;
        text-transform: uppercase;
        margin-left: 7px;
        font-size: 11px;
        border: none;
        color: #fff;

        a {
          color: inherit;
        }

        &:nth-child(1) {
          background-color: var(--primary-color);
        }
        &:nth-child(2) {
          background-color: var(--secondary-color);
        }
        &:nth-child(3) {
          background-color: var(--tertiary-color);
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .results {
    width: 758px;
  }
}

@media screen and (max-width: 1330px) {
  .results {
    max-width: 100%;
    min-width: 100%;
  }
}
