.new-story__publish--publish-info__tags div {
  font-size: 14px;
  border-radius: 3px;
  color: var(--theme-dark);
  margin: 0 5px 5px 0;
  padding: 5px;
  display: flex;
  display: inline-block;
  align-items: center;
  width: fit-content;
}

.new-story__publish--publish-info__tags div svg {
  font-size: 10px;
  margin-left: 5px;
  cursor: pointer;
}

.new-story__publish--publish-info__tags {
  display: inline-block;
}

.new-story__publish--publish-info__tags-container {
  border: 1px solid red;
  width: 75%;
  border: 1px solid #c4c4c4;
}

.new-story__publish--publish-info__tag-input {
  border: none !important;
  outline: none;
  display: inline-block;
  height: 100%;
  background: none;
}
