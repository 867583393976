.documents {
  margin-left: 200px;
  min-height: calc(100vh - 120px);
  background-color: #eeeeee;
  padding: 30px 15px 0 30px;

  .documentsHeader {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      padding: 10px;
      text-transform: capitalize;
      font-family: inherit;
      border: none;
      background-color: var(--primary-color);
      color: #fff;
    }
  }

  .documentsContainer {
    padding: 25px 0;
    display: flex;

    .tabs_table {
      width: 100%;
      flex: 1;

      .tabs {
        margin-left: 20px;
        background-color: #fff;
        border-bottom: 1px solid #f3f3f3;

        button {
          background: none;
          border: none;
          padding: 20px 30px;

          &:nth-child(1) {
            border-right: 1px solid #f3f3f3;
          }
        }
      }
    }

    .documentsContainerActions {
      min-width: 350px;

      .actionsHeader {
        // margin-bottom: 20px;
        background-color: #fff;
        padding: 20px 20px;
        // margin-bottom: 20px;

        span {
          font-size: 16px;
          font-weight: 700;
        }
      }

      .actionsSort {
        padding: 30px 20px;
        background-color: #fff;

        .actionsSortHeader {
          span {
            // font-weight: 600;
            font-size: 13px;
          }
        }

        select {
          padding: 10px;
          width: 100%;
          margin: 20px 0 10px;
          font-family: inherit;
          outline: none;
        }

        .actionsFilter {
          background-color: #f0efef;
          padding: 10px;
          // margin-top: 20px;

          span {
            font-size: 13px;
            display: block;
            margin-bottom: 10px;
          }

          input {
            border: 1px solid #c4c4c4;
            padding: 10px;
            width: 100%;
            outline: none;
          }

          div {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;

            button {
              &:nth-child(1) {
                padding: 10px 40px;
                background-color: #fff;
                border: none;
              }

              &:nth-child(2) {
                padding: 10px 40px;
                background-color: var(--primary-color);
                color: #fff;
                border: none;
              }
            }
          }
        }
      }

      .actionsMetrics {
        display: grid;
        grid-template-columns: auto auto;
        gap: 10px;
        margin-top: 20px;

        div {
          width: 200px;
          height: 70px;
          background-color: #c4c4c4;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          // &:nth-child(3) {
          //   border: 1px solid red;
          //   width: 100%;
          // }

          span {
            &:nth-child(1) {
              font-size: 12px;
              margin-bottom: 5px;
              display: block;
            }
          }
        }
      }
    }

    .documentsContainerTable {
      flex-grow: 1;
      margin-left: 20px;
      background-color: #fff;
      table {
        width: 100%;

        tr {
          display: block;
          display: flex;
          align-items: center;
          height: 70px;

          &:nth-child(even) {
            background-color: #f5f5f5;
          }

          th {
            font-size: 14px;
            color: var(--primary-color);
          }

          td {
            font-size: 12px;

            img {
              width: 18px;
              margin-left: 10px;
              cursor: pointer;
            }
          }

          th,
          td {
            &:nth-child(1) {
              width: 10%;
              display: flex;
              justify-content: center;
            }

            &:nth-child(2) {
              width: 25%;
              display: flex;
              justify-content: flex-start;
            }

            &:nth-child(3) {
              width: 35%;
              display: flex;
              justify-content: flex-start;
            }

            &:nth-child(5) {
              width: 15%;
              display: flex;
              justify-content: flex-start;
            }

            &:nth-child(6) {
              width: 5%;
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}
