.new {
  padding: 30px;
  margin-left: 200px;
  background-color: #eeeeee;
  min-height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;

  .newDocumentsHeader {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      padding: 10px;
      text-transform: capitalize;
      font-family: inherit;
      border: none;
      background-color: var(--primary-color);
      color: #fff;
    }
  }

  .main {
    background-color: #fff;
    padding: 50px 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .share {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.583);
      display: grid;
      justify-content: center;
      z-index: 2;

      .share_modal {
        width: 450px;
        height: 200px;
        background-color: #fff;
        box-shadow: 0.5px 1px 10px #d0cfcf;
        z-index: 2;
        display: flex;
        flex-direction: column;

        .share_modal_header {
          background-color: #cdcdcd;
          height: 60px;
          display: grid;
          place-items: center;
          position: relative;
          margin-bottom: 30px;

          img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 20px;
            cursor: pointer;
          }
        }

        .share_modal_input {
          padding: 0 30px;
          display: flex;
          align-items: center;
          flex-direction: column;

          span {
            font-weight: 600px;

            &:nth-child(2) {
              margin-top: 20px;
              font-size: 13px;

              a {
                color: var(--primary-color);
              }
            }
          }

          label {
            font-size: 12px;
            display: block;
            margin-top: 20px;
          }

          .share_modal_input_group {
            display: flex;
            border: 1px solid #eeeeee;
            margin-top: 10px;

            input {
              flex: 1;
              padding: 7px;
              border: none;
              outline: none;
            }

            div {
              width: 50px;
              display: grid;
              place-items: center;
              background-color: var(--primary-color);

              img {
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .statusContainer {
      display: flex;
      justify-content: space-between;
      width: 1200px;
      margin: auto;

      .status {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        width: 120px;

        span {
          text-align: center;
          margin-top: 10px;
          font-size: 12px;
        }

        &::after {
          position: absolute;
          content: "";
          display: block;
          width: 100px;
          height: 2px;
          background-color: #000;
          right: -100%;
          top: 50%;
          transform: translateY(-50%);
        }

        &:nth-child(5) {
          &::after {
            position: absolute;
            content: "";
            display: none;
          }
        }
      }
    }

    @media screen and (max-width: 1500px) {
      .statusContainer {
        width: min(800px, 1100px);

        .status {
          width: 80px;

          &::after {
            width: 50px;
          }
        }
      }
    }
  }

  .eventDiv {
    margin-top: 50px;
    flex-grow: 1;
    display: flex;
    align-items: center;

    .fileUploadContainer {
      margin: auto;
      width: 800px;

      .title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        span {
          margin-right: 10px;
          font-size: 14px;
        }

        input {
          padding: 10px;
          flex-grow: 1;
          outline: none;
          border: 1px solid #c4c4c4;
        }
      }

      .fileUpload {
        width: 100%;
        border: 3px dashed #c4c4c4;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;

        .imgIcon {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 30px;
          p {
            margin-top: 20px;
            font-size: 13px;
          }
        }

        .actionBtn {
          margin-top: 50px;

          label {
            color: #fff;
            cursor: pointer;
            padding: 10px 20px;
            font-size: 13px;
            background-color: var(--primary-color);
          }
          input {
            display: none;
          }
        }

        .fileName {
          margin-top: 20px;
        }

        .uploadButton {
          margin-top: 30px;
          width: 200px;
          display: flex;
          justify-content: flex-end;
          position: relative;
          min-height: 35px;
          align-self: flex-end;

          button {
            color: #fff;
            height: 35px;
            min-width: 100px;
            padding: 5px 20px;
            font-size: 13px;
            border: none;
            background-color: var(--primary-color);
          }
        }
      }
    }

    .extraction {
      margin: auto;
      width: 800px;
      position: relative;
      padding: 50px 50px 10px;
      border: 1px solid #c4c4c4;

      .extractionHeader {
        width: 200px;
        height: 50px;
        border: 1px solid #c4c4c4;
        position: absolute;
        top: -25px;
        left: 50px;
        background-color: #fff;
        display: grid;
        place-items: center;

        span {
          font-size: 14px;
          font-weight: 600;
        }
      }

      .fileInfo {
        margin-bottom: 30px;
        h5 {
          color: var(--primary-color);
        }
        span {
          display: block;
          font-size: 13px;
          font-weight: 600;
          margin-top: 10px;
        }
      }
    }

    .gazette,
    .publish {
      //   border: 1px solid red;
      display: flex;
      width: 100%;
      justify-content: space-around;
      min-height: 700px;

      .gazette_document {
        min-width: 40%;
        background-color: #fff;
        border: 1px solid #c4c4c4;
        min-height: 100%;
        display: flex;
        flex-direction: column;

        .gazette_document_tabs {
          background-color: #fafafa;
          display: flex;
          height: 50px;
          margin-bottom: 20px;

          div {
            display: flex;
            align-items: center;
            padding: 0 20px;

            &:nth-child(1) {
              background-color: #fff;

              p {
                font-size: 12px;
              }
            }

            &:nth-child(2) {
              flex: 1;
              border-left: 1px solid #c4c4c4;
              border-bottom: 1px solid #c4c4c4;
              button {
                border: none;
                background-color: var(--primary-color);
                color: #fff;
                font-size: 12px;
                padding: 4px 5px;

                a {
                  color: #fff;
                }
              }
            }
          }
        }

        .gazette_file {
          width: 90%;
          margin: auto;
          padding-bottom: 20px;
          height: 100%;

          .page_counter {
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            background-color: #3f3f3f;

            span {
              font-size: 12px;
              color: #fff;
            }

            button {
              background: none;
              border: none;
              margin: 0 10px;

              img {
                width: 10px;
              }
            }
          }

          .gazette_file_pdf {
            display: flex;
            justify-content: center;
            overflow-x: hidden;
          }
        }
      }

      .gazette_transcribing {
        min-width: 40%;

        .gazette_transcribingField {
          margin: 20px 0;
          textarea {
            width: 100%;
            height: 200px;
            resize: none;
            outline: none;
            padding: 10px;
            font-family: inherit;
          }
        }

        .gazette_transcribingBtns {
          display: flex;
          justify-content: flex-end;

          button {
            margin-left: 20px;
            &:nth-child(1) {
              padding: 2px 10px;
              font-size: 13px;
              background-color: #fff;
              color: var(--primary-color);
              border: 1px solid var(--primary-color);
            }

            &:nth-child(2) {
              padding: 2px 10px;
              font-size: 13px;
              background-color: var(--primary-color);
              color: #fff;
              border: 1px solid var(--primary-color);
            }
          }
        }
      }

      .gazette_info {
        width: 40%;
        background-color: #fff;
        padding-bottom: 20px;

        & > * {
          display: block;
        }

        h4 {
          padding: 20px 20px 5px;
          text-decoration: underline;
          font-size: 14px;
        }

        .gazette_category {
          color: #979797;
          font-style: italic;
          font-size: 14px;
        }

        .gazette_date,
        .gazette_senate {
          font-size: 12px;
        }

        span {
          padding: 5px 30px;
        }

        .gazette_tags {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-right: 20px;

          .tags {
            span {
              &:nth-child(1) {
                font-size: 12px;
                padding: 5px 10px 5px 30px;
              }
            }

            .tag_box {
              font-size: 10px;
              background-color: #c7c7c7;
              padding: 1px 10px;
              margin-right: 10px;
            }
          }

          .action_buttons {
            button {
              margin-left: 10px;
              border: none;
              padding: 5px 5px 2px;

              img {
                width: 15px;
                aspect-ratio: 1;
              }

              &:nth-child(1) {
                background-color: var(--primary-color);
              }
              &:nth-child(2) {
                background-color: var(--secondary-color);
              }
              &:nth-child(3) {
                background-color: var(--tertiary-color);
              }
            }
          }
        }
      }
    }

    .editContainer {
      margin: auto;
      width: 800px;
      position: relative;
      padding: 10px 50px 10px;

      .editContainerField {
        margin-bottom: 20px;
        position: relative;

        label {
          display: block;
          margin-bottom: 10px;
          font-size: 14px;
          font-weight: 600;
          color: var(--primary-color);
        }

        input,
        select {
          font-family: inherit;
          outline: none;
          padding: 15px 10px;
          width: 75%;
          border: 1px solid #c4c4c4;
        }
      }

      .editContainerBtn {
        button {
          background-color: var(--primary-color);
          border: none;
          padding: 7px 10px;
          color: #fff;
        }
      }
    }

    .publish {
      padding: 0 30px;

      .gazette_document {
        min-width: 50%;
      }

      .gazette_summary {
        margin-top: 50px;
        background-color: #fff;

        h5 {
          padding: 20px 20px 10px;
          border-bottom: 1px solid #979797;
          color: #2d2d2d;
          font-size: 14px;
          font-weight: 400;
          font-style: italic;
        }

        p {
          padding: 10px 50px 30px;
          font-size: 11px;
        }
      }
    }
  }
}
