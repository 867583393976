.active_page {
  background-color: var(--primary-color);
  color: #fff;
}

.documents {
  margin-left: 200px;
  min-height: calc(100vh - 120px);
  background-color: #eeeeee;
  padding: 30px 15px 0 30px;

  .documentsHeader {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      padding: 10px;
      text-transform: capitalize;
      font-family: inherit;
      border: none;
      background-color: var(--primary-color);
      color: #fff;
    }
  }

  .profile_pagination {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    background-color: #f5f5f5;

    input {
      width: 400px;
      min-width: 50%;
      outline: none;
      padding: 8px 5px;
      border: 1px solid #c4c4c4;
    }

    section {
      display: flex;
      flex: 1;
      justify-content: flex-end;

      div {
        margin-left: 30px;

        span {
          font-size: 13px;
          font-weight: 400;
          margin-right: 10px;
        }

        select {
          border: none;
          font-family: inherit;
          padding: 10px;
          cursor: pointer;
        }
      }
    }

    button {
      height: 100%;
      border: none;
      background: none;
      margin-right: 20px;
    }
  }

  .documentsContainer {
    background-color: #fff;
    padding: 25px;
  }

  .page_pagination {
    display: flex;
    justify-content: flex-end;
    ul {
      display: flex;
      justify-content: center;

      li {
        padding: 10px;
        cursor: pointer;

        &:hover {
          background-color: var(--primary-color);
          a {
            color: #fff;
          }
        }
      }

      li.selected {
        border: 1px solid red;
      }
    }
  }
}
