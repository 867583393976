.sidebar {
  width: 200px;
  // height: 100vh;
  height: calc(100vh - 120px);
  margin-top: 20px;
  background-color: #008000;

  .sidebarLinks {
    width: 100%;

    li {
      height: 50px;
      width: 100%;
      display: flex;
      align-items: center;
      padding-left: 30px;
      font-size: 10px;
      font-weight: 500;
      color: #fff;
      border-bottom: 1px solid rgba(0, 0, 0, 0.22);

      &:hover {
        background-color: #fff;
        color: #000;
      }
    }

    .webLink {
      background-color: #00b300;

      &:hover {
        background-color: #fff;
        color: #000;
      }
    }

    .active {
      li {
        background-color: #fff;
        color: #000;
      }
    }

    .logout {
      &:hover {
        color: #000 !important;
      }
    }

    div {
      padding-left: 30px;

      button {
        border: none;
        // background-color: rgba(255, 255, 255, 0.796);
        padding: 5px 8px;
      }
    }
  }
}
