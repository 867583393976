.publish_container {
  background-color: #eee;
  padding: 20px;
  min-height: 100vh;

  .publish_container_title {
    margin-left: 200px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  .publish {
    //   border: 1px solid red;
    display: flex;
    // width: 100%;
    justify-content: space-around;
    margin-left: 200px;
    // width: calc(100vw - 200px);
    height: calc(100vh - 200px);
    padding: 20px 50px;
    background-color: #fff;

    .gazette_document {
      min-width: 40%;
      background-color: #fff;
      border: 1px solid #c4c4c4;
      max-height: 100%;
      flex-grow: 1;
      margin-left: 50px;
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;

      .gazette_document_tabs {
        background-color: #fafafa;
        display: flex;
        height: 50px;
        margin-bottom: 20px;

        div {
          display: flex;
          align-items: center;
          padding: 0 20px;

          &:nth-child(1) {
            background-color: #fff;

            p {
              font-size: 12px;
            }
          }

          &:nth-child(2) {
            flex: 1;
            border-left: 1px solid #c4c4c4;
            border-bottom: 1px solid #c4c4c4;
            button {
              border: none;
              background-color: var(--primary-color);
              color: #fff;
              font-size: 12px;
              padding: 4px 5px;

              a {
                color: #fff;
              }
            }
          }
        }
      }

      .gazette_file {
        width: 90%;
        margin: 0 auto;
        // padding-bottom: 20px;
        flex-grow: 1;
      }
    }

    .gazette_transcribing {
      min-width: 40%;

      .gazette_transcribingField {
        margin: 20px 0;
        textarea {
          width: 100%;
          height: 200px;
          resize: none;
          outline: none;
          padding: 10px;
          font-family: inherit;
        }
      }

      .gazette_transcribingBtns {
        display: flex;
        justify-content: flex-end;

        button {
          margin-left: 20px;
          &:nth-child(1) {
            padding: 2px 10px;
            font-size: 13px;
            background-color: #fff;
            color: var(--primary-color);
            border: 1px solid var(--primary-color);
          }

          &:nth-child(2) {
            padding: 2px 10px;
            font-size: 13px;
            background-color: var(--primary-color);
            color: #fff;
            border: 1px solid var(--primary-color);
          }
        }
      }
    }

    .gazette_info {
      width: 40%;
      background-color: #fff;
      padding-bottom: 20px;
      height: fit-content;

      & > * {
        display: block;
      }

      h4 {
        padding: 20px 20px 5px;
        text-decoration: underline;
        font-size: 14px;
      }

      .edit {
        padding: 20px 20px 5px;
        button {
          border: none;
          color: #fff;
          background-color: var(--primary-color);
        }
      }

      .gazette_category {
        color: #979797;
        font-style: italic;
        font-size: 14px;
      }

      .gazette_date,
      .gazette_senate {
        font-size: 12px;
      }

      .gazette_summary,
      .audio {
        padding-left: 30px;
        margin-top: 30px;

        h5 {
          font-size: 14px;
          margin-bottom: 10px;
        }

        p {
          font-size: 12px;
        }
      }

      span {
        padding: 5px 30px;
      }

      .gazette_tags {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 20px;

        .tags {
          span {
            &:nth-child(1) {
              font-size: 12px;
              padding: 5px 10px 5px 30px;
            }
          }

          .tag_box {
            font-size: 10px;
            background-color: #c7c7c7;
            padding: 1px 10px;
            margin-right: 10px;
          }
        }

        .action_buttons {
          button {
            margin-left: 10px;
            border: none;
            padding: 5px 5px 2px;

            img {
              width: 15px;
              aspect-ratio: 1;
            }

            &:nth-child(1) {
              background-color: var(--primary-color);
            }
            &:nth-child(2) {
              background-color: var(--secondary-color);
            }
            &:nth-child(3) {
              background-color: var(--tertiary-color);
            }
          }
        }
      }
    }
  }
}
