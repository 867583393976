.search {
  background-color: #eeeeee;
  min-height: 100vh;
  margin-left: 200px;

  main {
    width: 1150px;
    margin: 0px auto 0;
    display: flex;
    padding-top: 50px;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 1500px) {
  .search {
    main {
      width: 1100px;
    }
  }
}

@media screen and (max-width: 1330px) {
  .search {
    main {
      width: unset;
      max-width: 100%;
      padding: 0 10px;
      flex-direction: column-reverse;
    }
  }
}
