.search {
  height: 80px;
  background-color: #fff;

  .search_component {
    height: inherit;
    margin: auto;
    max-width: 500px;
    display: flex;
    align-items: center;

    .search_input {
      display: flex;
      align-items: center;
      width: 740px;
      height: 50px;
      border: 1px solid #cdcdcd;
      padding-left: 10px;

      input {
        margin-left: 20px;
        flex-grow: 1;
        height: 35px;
        border: none;
        outline: none;

        &::placeholder {
          font-family: inherit;
        }
      }

      button {
        height: inherit;
        aspect-ratio: 1;
        display: grid;
        place-items: center;
        background-color: var(--primary-color);
        border: none;

        img {
          width: 23px;
          aspect-ratio: 1;
        }
      }
    }

    .search_trigger {
      button {
        margin-left: 30px;
        font-size: 15px;
        text-decoration: underline;
        background-color: #fff;
        border: none;
      }
    }
  }
}
