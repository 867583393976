.documents {
  margin-left: 200px;
  min-height: calc(100vh - 120px);
  background-color: #eeeeee;
  padding: 30px;
  overflow-x: hidden;
  position: relative;

  .share {
    position: absolute;
    height: 100%;
    width: 99%;
    background-color: rgba(255, 255, 255, 0.583);
    display: grid;
    place-items: center;
    z-index: 2;

    .share_modal {
      width: 400px;
      background-color: #fff;
      box-shadow: 0.5px 1px 10px #d0cfcf;
      z-index: 2;

      .share_modal_header {
        background-color: #eeeeee;
        height: 60px;
        display: grid;
        place-items: center;
        position: relative;
        margin-bottom: 30px;

        img {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 20px;
          cursor: pointer;
        }
      }

      .share_modal_input {
        padding: 0 50px 30px;
        label {
          display: block;
          font-size: 12px;
          margin-bottom: 3px;
        }

        input {
          width: 100%;
          margin-bottom: 20px;
          padding: 10px;
          outline: none;
          border: 1px solid #c4c4c4;
        }

        div {
          width: 100%;
          display: flex;
          justify-content: flex-end;

          button {
            padding: 10px;
            background-color: var(--primary-color);
            border: none;
            color: #fff;
          }
        }
      }
    }
  }

  .documentsHeader {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      padding: 10px;
      text-transform: capitalize;
      font-family: inherit;
      border: none;
      background-color: var(--primary-color);
      color: #fff;
    }
  }

  .documentsContainer {
    background-color: #fff;
    padding: 25px;
    table {
      width: 100%;

      tr {
        display: block;
        display: flex;
        align-items: center;
        height: 50px;

        &:nth-child(even) {
          background-color: #f5f5f5;
        }

        th {
          font-size: 14px;
          color: var(--primary-color);
        }

        td {
          font-size: 12px;

          img {
            width: 18px;
            margin-left: 10px;
            cursor: pointer;
          }
        }

        th,
        td {
          &:nth-child(1) {
            width: 10%;
            display: flex;
            justify-content: center;
          }

          &:nth-child(2) {
            width: 35%;
            display: flex;
            justify-content: flex-start;
          }

          &:nth-child(3) {
            width: 25%;
            display: flex;
            justify-content: flex-start;
          }

          &:nth-child(4) {
            width: 5%;
            display: flex;
            justify-content: flex-start;

            button {
              border: none;
              padding: 3px 10px;
              margin-right: 10px;

              &:nth-child(1) {
                background-color: #424242;
                color: #fff;
              }
              &:nth-child(2) {
                background-color: #c2c2c2;
                color: #222;
              }
            }
          }
        }
      }
    }
  }
}
