.signin {
  main {
    display: flex;
    align-items: center;
    padding: 10px 100px 10px 50px;

    .logo_landing {
      margin-right: 50px;
      p {
        margin-top: 20px;
        line-height: 30px;
      }
    }

    .login {
      height: 740px;
      width: 540px;
      padding: 20px 40px;
      background-color: #fff;
      box-shadow: 1px 1px 8px #d0cfcf;

      h3 {
        color: var(--primary-color);
        font-size: 20px;
        margin-bottom: 100px;
      }

      div {
        form {
          .form_error {
            color: red;
            text-align: left;
            margin-bottom: 10px;
          }

          .form_login_box {
            position: relative;
            height: fit-content;
            margin: 15px 0 30px 0;

            .form_login_box_input {
              width: 100%;
              padding: 10px;
              border: 1px solid #c4c4c4;
              margin: 0;
              outline: none;
              font-family: inherit;
            }

            img {
              width: 20px;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              cursor: pointer;
              right: 20px;
            }
          }

          .form_processing {
            color: var(--primary-color);
          }

          label {
            display: block;
            font-weight: 600;
          }

          .form_login_box {
            position: relative;

            img {
              width: 20px;
            }
          }

          input {
            width: 100%;
            padding: 10px;
            border: 1px solid #c4c4c4;
            outline: none;
            margin: 20px 0 40px 0;
            font-family: inherit;
          }

          .form_submit {
            display: block;
            width: 100%;
            padding: 10px;
            background-color: var(--primary-color);
            color: #fff;
            border: none;
            font-family: inherit;
          }

          span {
            margin-top: 20px;
            display: block;
            text-align: center;

            button {
              color: var(--primary-color);
              background: none;
              border: none;
              font-family: inherit;
            }
          }
        }
      }
    }

    @media (max-height: 800px) {
      .login {
        height: 700px;
      }
    }

    .signup {
      height: 740px;
      width: 540px;
      padding: 20px 40px;
      background-color: #fff;
      box-shadow: 1px 1px 8px #d0cfcf;

      h3 {
        color: var(--primary-color);
        font-size: 20px;
        margin-bottom: 70px;
      }

      div {
        form {
          .form_error {
            color: red;
            text-align: left;
            margin-bottom: 10px;
          }

          .form_processing {
            color: var(--primary-color);
          }

          label {
            display: block;
            font-weight: 600;
          }

          .form_login_box {
            position: relative;
            height: fit-content;
            margin: 15px 0 30px 0;

            .form_login_box_input {
              width: 100%;
              padding: 10px;
              border: 1px solid #c4c4c4;
              margin: 0;
              outline: none;
              font-family: inherit;
            }

            img {
              width: 20px;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              cursor: pointer;
              right: 20px;
            }
          }

          input {
            width: 100%;
            padding: 10px;
            border: 1px solid #c4c4c4;
            outline: none;
            margin: 15px 0 30px 0;
            font-family: inherit;
          }

          .form_submit {
            display: block;
            width: 100%;
            padding: 10px;
            background-color: var(--primary-color);
            color: #fff;
            border: none;
            font-family: inherit;
          }

          span {
            margin-top: 20px;
            display: block;
            text-align: center;

            button {
              color: var(--primary-color);
              background: none;
              border: none;
              font-family: inherit;
            }
          }
        }
      }
    }
  }
}

@media (max-height: 880px) {
  .signin {
    margin-top: -100px;
  }
}
