.navbar {
  width: 100vw;
  height: 120px;
  display: flex;
  align-items: center;
  padding: 20px 30px 0;
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 2;

  & > * {
    height: 100%;
    display: inherit;
    align-items: center;
  }

  .navbar_logo {
    flex-grow: 1;

    img {
      width: 300px;
      height: 75px;
      // object-fit: contain;
    }
  }

  .navbar_links {
    ul {
      height: inherit;
      display: flex;
      align-items: center;

      li {
        height: inherit;
        font-size: 14px;
        display: flex;
        align-items: center;
        padding: 0 25px;

        a {
          font-weight: 600;
        }
      }
    }
  }

  .navbar_search {
    padding: 0 15px;
    display: flex;
    align-items: center;
    margin-left: 30px;
    position: relative;
    background: #c7c7c7;
    height: 50px;
    margin-right: 0px;

    img {
      width: 25px;
      aspect-ratio: 1;
      cursor: pointer;
    }

    .logout {
      position: absolute;
      bottom: -30px;
      left: 0;
      background-color: #fff;
      width: 100%;

      h3 {
        font-size: 14px;
        text-align: right;
        cursor: pointer;
      }
    }

    button {
      border: none;
      font-family: inherit;
      font-weight: 600;
      background: none;
      height: 30px;
      display: flex;
      align-items: center;

      &:nth-child(2) {
        display: flex;
        align-items: center;
        padding-left: 10px;
        img {
          width: 10px;
        }
      }
    }
  }

  .new {
    background: var(--primary-color);

    button {
      color: #fff;
    }
  }
}
