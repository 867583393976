.active_page {
  background-color: var(--primary-color);
  color: #fff;
}

.documents {
  margin-left: 200px;
  min-height: calc(100vh - 120px);
  background-color: #eeeeee;
  padding: 30px;

  .documentsHeader {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      padding: 10px;
      text-transform: capitalize;
      font-family: inherit;
      border: none;
      background-color: var(--primary-color);
      color: #fff;
    }
  }

  .documentsContainer {
    background-color: #fff;
    padding: 25px;
    table {
      width: 100%;

      tr {
        display: block;
        display: flex;
        align-items: center;
        height: 50px;

        &:nth-child(even) {
          background-color: #f5f5f5;
        }

        th {
          font-size: 14px;
          color: var(--primary-color);
        }

        td {
          font-size: 12px;

          img {
            width: 18px;
            margin-left: 10px;
            cursor: pointer;
          }
        }

        th,
        td {
          &:nth-child(1) {
            width: 10%;
            display: flex;
            justify-content: flex-start;
            padding: 0 15px;
          }

          &:nth-child(2) {
            width: 35%;
            display: flex;
            justify-content: flex-start;
          }

          &:nth-child(3) {
            width: 15%;
            display: flex;
            justify-content: flex-start;
          }

          &:nth-child(4) {
            width: 15%;
            display: flex;
            justify-content: flex-start;
          }

          &:nth-child(5) {
            width: 5%;
            display: flex;
            justify-content: flex-start;

            button {
              border: none;
              padding: 3px 10px;
              margin-right: 10px;

              &:nth-child(1) {
                background-color: #424242;
                color: #fff;
              }

              &:nth-child(2) {
                background-color: #da6868;
                color: #fff;
              }
              // &:nth-child(2) {
              //   background-color: #c2c2c2;
              //   color: #222;
              // }
            }

            .activate {
              background-color: var(--primary-color) !important;
            }
          }
        }
      }
    }
  }

  .page_pagination {
    display: flex;
    justify-content: flex-end;
    ul {
      display: flex;
      justify-content: center;

      li {
        padding: 10px;
        cursor: pointer;

        &:hover {
          background-color: var(--primary-color);
          a {
            color: #fff;
          }
        }
      }

      li.selected {
        border: 1px solid red;
      }
    }
  }
}
