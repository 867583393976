@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400&display=swap");

:root {
  --primary-color: #129017;
  --secondary-color: #540088;
  --tertiary-color: #c98500;
  --primary-grey: #424242;
  --secondary-grey: #929292;
  --tertiary-grey: #c2c2c2;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  /* background-color: #eeeeee; */
  font-family: "Montserrat", "Helvetica Neue", "Arial", "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: inherit;
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style: none;
}

input {
  font-family: inherit;
}

.react-confirm-alert-body h1 {
  font-size: 20px !important;
  margin-bottom: 30px;
}

.react-confirm-alert-button-group button:nth-child(1) {
  background-color: var(--primary-color);
}

.react-confirm-alert-button-group button:nth-child(2) {
  background-color: red;
}
